/**
 * Script provided by fonts.com.
 */
var MTUserId = "1cd58f69-18c2-4b29-ad32-3434009e06d4";
var MTFontIds = new Array();

MTFontIds.push("812328"); // ITC Cheltenham® W01 Bold
MTFontIds.push("812337"); // ITC Cheltenham® W01 Bold Italic
MTFontIds.push("812340"); // ITC Cheltenham® W01 Book
MTFontIds.push("812349"); // ITC Cheltenham® W01 Book Italic
MTFontIds.push("812352"); // ITC Cheltenham® W01 Light
MTFontIds.push("812361"); // ITC Cheltenham® W01 Light Italic
MTFontIds.push("812364"); // ITC Cheltenham® W01 Ultra
MTFontIds.push("812373"); // ITC Cheltenham® W01 Ultra Italic

var userId = MTUserId;
var pf = MTFontIds.join(",");
var mtTracking = document.createElement("link");
mtTracking.type = "text/css";
mtTracking.rel = "stylesheet";
mtTracking.href =
  ("https:" == document.location.protocol ? "https:" : "http:") +
  "//fast.fonts.net/lt/1.css?apiType=css&c=" +
  userId +
  "&fontids=" +
  pf;
(
  document.getElementsByTagName("head")[0] ||
  document.getElementsByTagName("body")[0]
).appendChild(mtTracking);
